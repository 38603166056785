<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <h3>Field Assistant Requests</h3>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <select v-model="status" class="form-control" @change="fetch">
            <option value="pending">Pending</option>
            <option value="active">Active</option>
            <option value="completed">Completed</option>
            <option value="declined">Declined</option>
          </select>
        </div>
      </div>
    </div>
    <div class="card" v-if="! loading">
      <div class="card-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Category</th>
              <th>Region</th>
              <th>Package</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="requests.length == 0">
              <td colspan="9">There is no data to display</td>
            </tr>
            <tr v-for="(req, i) in requests" :key="`req-${i}`">
              <td>{{ i+1 }}</td>
              <td>{{ req.name }}</td>
              <td>{{ req.email }}</td>
              <td>{{ req.phone_number }}</td>
              <td>{{ req.category }}</td>
              <td>{{ req.region }}</td>
              <td><sub>KES</sub>{{ req.amount }}</td>
              <td>{{ req.status }}</td>
              <td>
                <router-link :to="{ name: 'admin.field-assistants.show', params: { id: req.id }}">Details</router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      requests: [],
      loading: true,
      status: 'pending'
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get(`/api/v1/admin/field-assistants?status=${this.status}`).then(response => {
        this.requests = response.data.requests
        this.loading = false
      })
    }
  }
}
</script>